import React, { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './MobileSettlementSection.scss';

gsap.registerPlugin(ScrollTrigger);
function MobileSettlementSection() {
  useEffect(() => {
    const container = document.querySelector('.panel-container');
    const mm = gsap.matchMedia();
    mm.add('(max-width:786px)', () => {
      const duration = 1;
      const delay = 3;
      // const borderColor = '#6933d3';
      const tl = gsap.timeline({
        yoyo: true,
        onComplete: () => {
          tl.play('panel-1');
        },
      });

      tl.to(container, { xPercent: 0, duration })
        .add('panel-1')
        .to(container, { xPercent: -25, duration, delay })
        .to(container, { xPercent: -50, duration, delay })
        .to(container, { xPercent: -75, duration, delay })
        .to(container, { xPercent: 0, duration, delay });

      return () => {
        tl.kill();
      };
    });
    return () => {
      mm.kill();
    };
  }, []);
  return (
    <>
      <section className="bg-cf-light relative block md:hidden mobile-settlement-section pt-12 pb-8">
        <div className="container relative">
          <div className="flex justify-center items-center flex-col min-h-[150px] mb-10">
            <div className="absolute font-heading text-center text-lg  text-cf-hero font-semibold typical-m-heading max-w-[310px]">
              <span>Typical</span> <span>settlements</span> <span>are</span>{' '}
              <span>processed on</span>{' '}
              <span className="text-cf-faded-black">T+2 working days</span>
            </div>
            {/* <div className="font-heading text-center text-lg  text-cf-hero font-semibold accelerate-m-heading max-w-[310px]">
              <span>Accelerate</span> <span>your</span> <span>settlements</span>{' '}
              <span>with</span>{' '}
              <span className="text-cf-primary">Instant settlements</span>
            </div> */}
          </div>
          <div className="panel-container">
            {[
              {
                id: 0,
                image: '/img/instant-settlements/enter-upi-w.png',
                description:
                  'Customer enters the payment details in Cashfree’s checkout forms',
                after: null,
              },
              {
                id: 1,
                image: '/img/instant-settlements/otp.png',
                description:
                  'The transaction is authenticated by the customer using OTP verification',
                after: null,
              },
              {
                id: 2,
                image: '/img/instant-settlements/payment-done.png',
                description:
                  'The amount is received at Cashfree’s acquiring banks',
                after: null,
              },
              {
                id: 3,
                image: '/img/instant-settlements/amount-credit.png',
                description:
                  'Amount is credited in merchant’s account in T+2 days after payment gateway deductions.',
                after: (
                  <div className="absolute top-0 p-2  t2-badge flex items-center z-50 bg-white ">
                    {/* <img
                    className="zap"
                    src="/img/icons/yellow-zap.svg"
                    alt=""
                    width="32"
                    height="32"
                  /> */}
                    <img
                      className="clock"
                      src="/img/icons/clock.svg"
                      alt=""
                      width="32"
                      height="32"
                    />
                    <div className="text-[12px] pl-2 flex flex-col h-[42px] overflow-hidden ">
                      <div className="badge-1">
                        <div className="mb-4 first-text">
                          Merchant receives money in T+2 days
                        </div>
                        {/* <div>Merchant receives the money instantly</div> */}
                      </div>
                    </div>
                  </div>
                ),
              },
            ].map(({ id, description, image, after }) => (
              <div className="panel" key={id}>
                <div className="mb-10 relative process-card">
                  <div className="rounded-sm mb-5 border border-cf-primary">
                    <img src={image} alt="" />
                  </div>
                  <div>
                    <p className="text-sm text-center">{description}</p>
                  </div>
                  {after}
                </div>
              </div>
            ))}
            <div className="line" />
          </div>
          {/* <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-center full-width-buttons m-buttons">
            <a
              className="button button-green"
              href="https://merchant.cashfree.com/merchants/signup?source-action=Instant%20settlements&action=Sign%20Up&button-id=StartNow_AccelerateYourSettlements"
              style={{ margin: '5px' }}>
              Create Account <span className="chevron" />
            </a>
            <a
              className="button button-light no-shadow text-cf-hero border-cf-stroke"
              style={{ margin: '5px' }}
              href="https://www.cashfree.com/contact-sales?source-action=Instant%20settlements&action=Contact%20Sales&button-id=ContactSales_AccelerateYourSettlements">
              Contact Sales <span className="chevron dark !mb-0" />
            </a>
          </div> */}
        </div>
      </section>
      <section className="bg-cf-light relative block md:hidden mobile-settlement-section pb-12 pt-4">
        <div className="container relative">
          <div className="flex justify-center items-center flex-col min-h-[150px] mb-10">
            <div className="font-heading text-center text-lg  text-cf-hero font-semibold accelerate-m-heading max-w-[310px]">
              <span>Accelerate</span> <span>your</span> <span>settlements</span>{' '}
              <span>with</span>{' '}
              <span className="text-cf-primary">Instant settlements</span>
            </div>
          </div>
          <div className="mx-[-2.5%]">
            {[
              {
                id: 1,
                image: '/img/instant-settlements/amount-credit-back.png',
                description:
                  'Cashfree Payments credits the merchant’s accounts instantly after Payment Gateway deduction',
                after: (
                  <div className="absolute top-0 p-2  t2-badge flex items-center z-50  bg-cf-hero">
                    <img
                      className="zap"
                      src="/img/icons/yellow-zap.svg"
                      alt=""
                      width="32"
                      height="32"
                    />
                    <div className="text-[12px] pl-2 flex flex-col h-[42px] overflow-hidden text-white ">
                      <div className="badge-1">
                        <div className="mb-4 first-text">
                          Merchant receives the money instantly
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
            ].map(({ id, description, image, after }) => (
              <div className="panel last-card" key={id}>
                <div className="mb-10 relative process-card">
                  <div className="rounded-sm mb-5 border border-cf-white">
                    <img src={image} alt="" />
                  </div>
                  <div>
                    <p className="text-sm text-center">{description}</p>
                  </div>
                  {after}
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-center full-width-buttons m-buttons">
            <a
              className="button button-green"
              href="https://merchant.cashfree.com/merchants/signup?source-action=Instant%20settlements&action=Sign%20Up&button-id=StartNow_AccelerateYourSettlements"
              style={{ margin: '5px' }}>
              Create Account <span className="chevron" />
            </a>
            <a
              className="button button-light no-shadow text-cf-hero border-cf-stroke"
              style={{ margin: '5px' }}
              href="https://www.cashfree.com/contact-sales?source-action=Instant%20settlements&action=Contact%20Sales&button-id=ContactSales_AccelerateYourSettlements">
              Contact Sales <span className="chevron dark !mb-0" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default MobileSettlementSection;
