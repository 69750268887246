import React, { useCallback, useState } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Icon from '../components/Icon';
import GetStarted from '../components/GetStarted';
import FAQ from '../components/FAQ';
import Scrollspy from '../components/Scrollspy';
import SetttlementSection from '../components/Sections/SetttlementSection/SetttlementSection';

import '../styles/components/left-text-hero.scss';
import '../styles/components/img-text-block.scss';
import '../styles/components/other-products.scss';
import '../styles/instant-settlement.scss';

import OtherProducts from '../components/OtherProducts';
import MobileSettlementSection from '../components/Sections/SetttlementSection/MobileSettlementSection';

const InstantSettlements = (props) => {
  const [accordionAnimImage, setAccordionAnimImage] = useState(0);

  const getAccordionAnimImage = useCallback((id) => {
    setAccordionAnimImage(id);
  }, []);

  return (
    <Layout
      {...props}
      signInLink="https://merchant.cashfree.com/merchant/login?source-action=Instant%20settlements&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=Instant%20settlements&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Instant Settlements with Cashfree Payment Gateway"
        description="Signup and get instant payment gateway settlements. Get access to your funds within seconds, even on a bank holiday!"
        url="https://www.cashfree.com/instant-settlements/"
        keywords={[
          'Instant settlement payment gateway',
          'Instant Settlement',
          'Payment gateway with Instant settlement',
          'fast settlements',
          'same-day settlements',
          'payment gateway settlement',
          'pg settlements',
        ]}
      />

      <section className="overflow-hidden relative pt-[110px] md:pt-[150px] pb-0">
        <div className="container relative">
          <div className="flex flex-wrap flex-col lg:flex-row">
            <div className="relative z-[1] w-full lg:w-1/2">
              <div className="mb-5 text-xl md:text-2xl font-semibold max-w-[590px] font-heading">
                Unlock cash flow certainty with{' '}
                <h1 className="inline">Instant Settlements</h1>
              </div>
              <div className="text-opacity-80 mb-[30px] max-w-[475px] text-[16px] leading-[24px] md:text-2.5md">
                Receive your{' '}
                <h2 className="inline font-body">
                  Payment Gateway settlements
                </h2>{' '}
                within seconds. Opt from a wide range of settlement options and
                never run out of&nbsp;funds.
              </div>

              <ul className="p-0 mt-6 md:mt-8 mb-4 md:mb-9 list-none max-w-full flex flex-col md:flex-row md:items-center justify-start">
                {[
                  {
                    id: 0,
                    listItem: 'Super-fast settlements',
                  },
                  {
                    id: 1,
                    listItem: 'Highly reliable',
                  },
                  {
                    id: 2,
                    listItem: 'Tailor-made for you',
                  },
                ].map(({ id, listItem }) => (
                  <li
                    className="mb-[15px] last:mb-0 md:last:mb-[15px] mr-[24px] lg:mr-4 xl:mr-[24px] last:mr-0 flex"
                    key={id}>
                    <span className="w-5 h-5 flex flex-none justify-center items-center rounded-full mr-2 bg-cf-primary">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    <h3 className="font-body !m-0 text-2.5sm md:text-md">
                      {listItem}
                    </h3>
                  </li>
                ))}
              </ul>
              <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-start full-width-buttons">
                <a
                  className="button button-green  md:mr-4"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=Instant%20settlements&action=Sign%20Up&button-id=StartNow_Hero"
                  // style={{ margin: '5px' }}
                >
                  Create Account <span className="chevron" />
                </a>
                <a
                  className="button button-outline no-shadow text-cf-hero border-cf-stroke"
                  // style={{ margin: '5px' }}
                  href="https://www.cashfree.com/contact-sales?source-action=Instant%20settlements&action=Contact%20Sales&button-id=ContactSales_Hero">
                  Contact Sales <span className="chevron dark !mb-0" />
                </a>
              </div>
            </div>
            <div className="z-[9] relative w-full lg:w-1/2 lg:-mt-12">
              <div className="relative text-center mt-8 lg:mt-0 lg:top-[-50px]">
                <video
                  autoPlay
                  muted
                  playsInline
                  loop
                  className="inset-video aspect-square chrome-video"
                  // className="chrome-video"
                  width="1600"
                  height="1420">
                  <source
                    src="/img/instant-settlements/hero-video.webm"
                    type="video/webm"
                  />
                  <source
                    src="/img/instant-settlements/hero-video_H264.mp4"
                    type="video/mp4"
                    // type="video/mp4;codecs=hvc1"
                  />
                </video>
                <video
                  autoPlay
                  muted
                  playsInline
                  loop
                  className="inset-video hidden aspect-square safari-video"
                  // className="chrome-video"
                  width="1600"
                  height="1420">
                  <source
                    src="/img/instant-settlements/hero-video_H264.mp4"
                    type="video/mp4"
                    // type="video/mp4;codecs=hvc1"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-6 md:mt-5 xl:mt-[-19px] mb-6 md:mb-[80px]">
        <div className="small-container">
          <div className="relative overflow-hidden">
            <div className="row justify-between max-w-[1000px] mx-auto marquee marquee-2 marqee-mob">
              <span className="mr-5">
                <img src="/img/irctc.svg" alt="irctc" width="35" height="44" />
              </span>
              <span className="mr-5">
                <img
                  src="/img/nykaa-dark.svg"
                  alt="nykaa"
                  width="122"
                  height="40"
                />
              </span>
              <span className="mr-5">
                <img
                  src="/img/ixigo-dark.svg"
                  width="65"
                  height="32"
                  alt="ixigo"
                />
              </span>
              <span className="mr-5">
                <img
                  src="/img/ritu-kumar-dark.svg"
                  alt="ritu-kumar"
                  width="102"
                  height="13"
                  style={{ maxWidth: '113px' }}
                />
              </span>
              <span className="mr-5">
                <img
                  src="/img/noise.svg"
                  alt="noise"
                  width="90"
                  height="26"
                  style={{ maxWidth: '88px' }}
                />
              </span>
              <span className="mr-5">
                <img
                  src="/img/easemytrip-dark.svg"
                  alt="easemytrip.com"
                  width="130"
                  height="48"
                  style={{ maxWidth: '98px' }}
                />
              </span>
            </div>
          </div>
        </div>
      </section>
      <Scrollspy
        centeredLinks
        leadContent={
          <div
            className="payout-nav-logo row row-no-margin justify-flex-start"
            style={{ minWidth: '190px' }}>
            <img
              src="/img/icons/instant-settlements-new.svg"
              alt="instant-settlements-icon"
              width="22"
              height="30"
            />
            <span>Instant Settlements</span>
          </div>
        }>
        <section
          data-spy-title="Features"
          data-spy-id="features"
          className="section-features p-section-3">
          <div className="container">
            <div className="text-shs md:text-shl font-semibold text-cf-hero max-w-[620px] mb-5 font-heading">
              Say goodbye to cash flow problems with{' '}
              <h2 className="inline">Instant Settlements</h2>
            </div>
            <div className="text-2.5sm md:text-md text-cf-hero max-w-[620px] mb-7">
              A usual Payment Gateway settlement cycle is T+2 days. This delay
              often leads to stress on your working capital. With{' '}
              <h2 className="inline font-body">
                Instant Settlements Payment Gateway
              </h2>
              , you&nbsp;can:
            </div>
            <ul className="p-0 mt-8 mb-0 md:mb-[37px] list-none max-w-full flex flex-wrap md:flex-nowrap items-center justify-start">
              {[
                {
                  id: 0,
                  listItem: 'Receive steady inflow of funds',
                },
                {
                  id: 1,
                  listItem: 'Instantly reconcile all your payments',
                },
              ].map(({ id, listItem }) => (
                <li
                  className=" mb-[15px] mr-[15px] flex items-center py-[10px] px-4 bg-cf-light rounded"
                  key={id}>
                  <span className="w-5 h-5 flex justify-center items-center rounded-full mr-2 bg-cf-primary">
                    <Icon
                      name="tick"
                      width="9.21px"
                      height="6.29px"
                      fill="#fff"
                    />
                  </span>
                  <h3 className="font-body !m-0 text-vtiny  md:text-2.5sm font-semibold">
                    {listItem}
                  </h3>
                </li>
              ))}
            </ul>

            <div className="flex flex-nowrap md:flex-wrap justify-start mx-[-15px]">
              <div className="flex-auto pl-[12px] pr-[23px] w-full md:w-6/12 hidden md:block">
                <div className="relative ">
                  <div
                    className={`hidden static transform-none mx-auto transition-all duration-300 ease${
                      accordionAnimImage === 0 ? ' !block' : ''
                    }`}>
                    <img
                      src="/img/instant-settlements/gear-up.svg"
                      alt=""
                      width="610"
                      height="449"
                    />
                  </div>
                  <div
                    className={`hidden static transform-none mx-auto transition-all duration-300 ease${
                      accordionAnimImage === 1 ? ' !block' : ''
                    }`}>
                    <img
                      src="/img/instant-settlements/get-smart.svg"
                      alt=""
                      width="610"
                      height="449"
                    />
                  </div>
                  <div
                    className={`hidden static transform-none mx-auto transition-all duration-300 ease${
                      accordionAnimImage === 2 ? ' !block' : ''
                    }`}>
                    <img
                      src="/img/instant-settlements/avoid-latefee.svg"
                      alt=""
                      width="610"
                      height="449"
                    />
                  </div>
                </div>
              </div>
              <div className="flex-auto px-[15px] w-full md:w-6/12 instant-settlement-cashfaq">
                <FAQ
                  supportLink={false}
                  arrow="arrow"
                  getActive={getAccordionAnimImage}
                  isPayouts
                  alwaysOpen
                  data={[
                    {
                      id: 0,
                      q: (
                        <div className="mt-0">
                          <span className="inline-block min-w-[30px] mr-3">
                            <img
                              className="inline"
                              src="/img/icons/green-signal.svg"
                              width="32"
                              height="32"
                              alt=""
                            />
                          </span>
                          Gear up for a spike in demand
                        </div>
                      ),
                      a: (
                        <>
                          <p className="md:pl-[45px] text-2sm md:text-md">
                            If you are running any festive or seasonal sale,
                            benefit from the immediate access to sales
                            collections with Instant Settlements and plan
                            optimum inventory level{' '}
                            <b>without dependency on external&nbsp;credit.</b>
                          </p>
                          <img
                            className="md:hidden mt-6"
                            src="/img/instant-settlements/gear-up.svg"
                            alt=""
                            width="610"
                            height="449"
                          />
                        </>
                      ),
                    },
                    {
                      id: 1,
                      q: (
                        <>
                          <span className="inline-block min-w-[30px] mr-3">
                            <img
                              className="inline"
                              src="/img/icons/yellow-bar-lg.svg"
                              width="32"
                              height="32"
                              alt=""
                            />
                          </span>
                          Get smarter with cash management
                        </>
                      ),
                      a: (
                        <>
                          <p className="md:pl-[45px] text-2sm md:text-md">
                            Have complete control over your working capital and
                            plan your cash reserves better without financial
                            uncertainties. Instant access to funds lets you
                            respond faster to business opportunities
                            and&nbsp;challenges.
                          </p>
                          <img
                            className="md:hidden mt-6"
                            src="/img/instant-settlements/get-smart.svg"
                            alt=""
                            width="610"
                            height="449"
                          />
                        </>
                      ),
                    },
                    {
                      id: 2,
                      q: (
                        <>
                          <span className="inline-block min-w-[30px] mr-3">
                            <img
                              className="inline"
                              src="/img/icons/purple-hammer.svg"
                              width="32"
                              height="32"
                              alt=""
                            />
                          </span>
                          Avoid late fees or penalties
                        </>
                      ),
                      a: (
                        <>
                          <p className="md:pl-[45px] text-2sm md:text-md">
                            With ready cash in hand, take care of operational
                            expenses like <b>vendor payments</b>, taxes, and
                            other liabilities on time without missing any
                            due&nbsp;dates.
                          </p>
                          <img
                            className="md:hidden mt-6"
                            src="/img/instant-settlements/avoid-latefee.svg"
                            alt=""
                            width="610"
                            height="449"
                          />
                        </>
                      ),
                    },
                  ]}
                  style={{
                    border: 'none',
                    margin: '0',
                    padding: '0',
                  }}
                />
                <div className="flex flex-wrap md:flex-nowrap justify-start mt-[30px] md:mt-[40px]">
                  <div className="w-full pr-2">
                    <a
                      className="button button-green"
                      href="https://merchant.cashfree.com/merchants/signup?source-action=Instant%20settlements&action=Sign%20Up&button-id=StartNow_SayGoodbye">
                      Create Account <span className="chevron" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-cf-hero settlement-cards-container p-section-2">
          <div className="container oval-container">
            <div className="text-cf-white text-4md md:text-xl max-w-[600px] font-semibold mb-[32px] md:mb-[40px] font-heading">
              Choose from multiple Settlement options as per your need
            </div>
            <div className="flex flex-wrap flex-row items-stretch mx-[-5%] md:mx-[-20px] mb-[25px] md:mb-[96px]">
              {[
                {
                  id: 0,
                  tag: 2,
                  icon: '/img/icons/green-tick-circle.svg',
                  heading: 'On-Demand Instant settlements',
                  description: (
                    <>
                      Withdraw your balance to your bank account in a single
                      click, when you need it the&nbsp;most.
                    </>
                  ),
                  link:
                    'https://merchant.cashfree.com/merchants/signup?source-action=Instant%20settlements&action=Sign%20Up&button-id=StartNow_OnDemandInstantSettlements',
                },
                {
                  id: 1,
                  tag: 2,
                  icon: '/img/icons/pink-calender.svg',
                  heading: 'Scheduled Instant Settlements',
                  description: (
                    <>
                      Access customer payments in the blink of an eye.
                      ​​Cashfree settles payments multiple times a day, starting
                      from intervals of 15 minutes, even on bank&nbsp;holidays.
                    </>
                  ),
                  link:
                    'https://merchant.cashfree.com/merchants/signup?source-action=Instant%20settlements&action=Sign%20Up&button-id=StartNow_ScheduledInstantSettlements',
                },
                {
                  id: 2,
                  tag: null,
                  icon: '/img/icons/green-circles.svg',
                  heading: 'Same-day Settlements',
                  description: (
                    <>
                      Effectively manage your daily operations. Get all your
                      settlements at the start of your day at 9 am and when you
                      close your books at&nbsp;5&nbsp;pm.
                    </>
                  ),
                  link:
                    'https://merchant.cashfree.com/merchants/signup?source-action=Instant%20settlements&action=Sign%20Up&button-id=StartNow_SameDaySettlements',
                },
              ].map(({ id, description, heading, icon, tag, link }) => (
                <div
                  key={id}
                  className="w-full md:w-1/3 px-[5%] md:px-[20px] mb-4 md:mb-0">
                  <div className="bg-cf-white p-4 md:p-6 h-full rounded flex flex-col justify-between">
                    <div>
                      <div className="w-[40px] h-[40px]">
                        <img className="w-full" src={icon} alt="" />
                      </div>
                      <div>
                        {tag === 2 ? (
                          <h2 className="font-body font-semibold text-2md mt-[14px] md:mt-[27px] mb-1 md:mb-2">
                            {heading}
                          </h2>
                        ) : (
                          <div className="font-body font-semibold text-2md mt-[14px] md:mt-[27px] mb-1 md:mb-2">
                            {heading}
                          </div>
                        )}
                        <p className="mb-10 text-2.5sm md:text-md">
                          {description}
                        </p>
                      </div>
                    </div>

                    <a
                      href={link}
                      className="btn p-0 text-cf-green group !text-2.5sm">
                      Activate Now
                      <svg
                        className="ml-2.5 group-hover:translate-x-[3px] transition-transform duration-300"
                        width="6"
                        height="11"
                        viewBox="0 0 6 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1 1L5 5.5L1 10"
                          stroke="#05C16E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-cf-white text-4md md:text-xl max-w-[600px] font-semibold mb-[32px] md:mb-[40px] font-heading">
              Receive the settlements in any destination of your choice
            </div>
            <div className="flex flex-wrap flex-row items-stretch mx-[-5%] md:mx-[-20px]">
              {[
                {
                  id: 0,
                  icon: '/img/icons/yellow-bookmark.svg',
                  heading: 'Bank Account',
                  description: (
                    <>
                      Get instant fund transfer straight to your
                      bank&nbsp;account.
                    </>
                  ),
                },
                {
                  id: 1,
                  icon: '/img/icons/green-persons.svg',
                  heading: 'Virtual account',
                  description: (
                    <>
                      Get your customer payments settled straight into your
                      virtual account. Use virtual account funds to pay money to
                      your vendors, do customer refunds, and
                      disburse&nbsp;loans.
                    </>
                  ),
                },
              ].map(({ id, description, heading, icon }) => (
                <div
                  key={id}
                  className="w-full md:w-1/3 px-[5%] md:px-[20px] mb-4 md:mb-0">
                  <div className="bg-cf-white p-4 md:p-6 min-h-[188px] h-full rounded flex flex-col justify-between">
                    <div>
                      <img
                        className="max-w-[30px] max-h-[30px] mb-3 md:mb-6"
                        src={icon}
                        alt=""
                      />
                      <div>
                        <div className="font-body font-semibold text-2md mb-1 md:mb-2">
                          {heading}
                        </div>
                        <p className="md:mb-6 text-2.5sm md:text-md md:pr-5">
                          {description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section
          data-spy-title="Pricing"
          data-spy-id="Pricing"
          className="py-12 md:py-32">
          <div className="container pricing-section">
            <div className="flex flex-wrap items-center justify-between mx-[-15px]">
              <div className="w-full p-[15px] xl:w-5/12">
                <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                  PRICING
                </p>
                <div className="text-cf-hero text-4md md:text-xl max-w-[400px] font-semibold md:mb-10 font-heading">
                  Flexible pricing plan, to suit your needs
                </div>
              </div>
              <div className="w-full p-[15px] xl:w-7/12">
                <div className="flex flex-col justify-center md:flex-row">
                  <div className="w-full xl:max-w-[334px] md:self-end">
                    <div className="p-4 md:p-6 starts-card">
                      <div className="text-md md:text-2sm text-cf-hero opacity-50">
                        Start At
                      </div>
                      <div className="text-cf-primary text-2xl starts-at relative font-medium">
                        0.2%
                      </div>
                      <ul className="mt-6 pt-6 pb-8">
                        {[
                          {
                            id: 0,
                            item: 'Zero Setup Fee',
                          },
                          {
                            id: 1,
                            item: 'Zero Maintenance Fee',
                          },
                        ].map(({ id, item }) => (
                          <li className="mb-4 flex" key={id}>
                            <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-2 bg-cf-primary flex-none">
                              <Icon
                                name="tick"
                                width="9.21px"
                                height="6.29px"
                                fill="#fff"
                              />
                            </span>
                            <h3 className="font-body !m-0 text-[15px]">
                              {item}
                            </h3>
                          </li>
                        ))}
                      </ul>
                      <a
                        className="button button-green w-full"
                        href="https://merchant.cashfree.com/merchants/signup?source-action=Instant%20settlements&action=Sign%20Up&button-id=StartNow_Pricing">
                        Create Account <span className="chevron" />
                      </a>
                    </div>
                    <small className="italic text-tiny pt-3 block pr-1 md:mb-3 font-normal">
                      * Might vary based on the type of Instant Settlements
                      chosen and the risk assessment of your business.{' '}
                      <span className="block">Price excludes GST.</span>
                    </small>
                  </div>

                  <div className="pricing-card-container w-full xl:max-w-[400px] bg-cf-hero border-t-[9px] border-cf-primary rounded-sm mt-12 md:mt-0">
                    <div className="card-content">
                      <small className="text-white">ENTERPRISES</small>
                      <p className="lead bold">
                        Custom pricing designed for enterprises
                      </p>
                      <ul className="flex flex-col text-2md">
                        {[
                          {
                            id: 0,
                            item: 'Early access to new features',
                          },
                          {
                            id: 1,
                            item: 'Dedicated account manager',
                          },
                          {
                            id: 2,
                            item: 'Discounted pricing',
                          },
                          {
                            id: 3,
                            item:
                              'Support over WhatsApp in addition to other channels',
                          },
                        ].map(({ id, item }) => (
                          <li key={id} className="flex mb-3">
                            <span className="flex items-center justify-center mt-0.5 w-5 h-5 rounded-full flex-none">
                              <Icon
                                name="tick"
                                width="9.2px"
                                height="7px"
                                fill="#fff"
                              />
                            </span>
                            <p className="!text-cf-white max-w-[300px] ml-2">
                              {item}
                            </p>
                          </li>
                        ))}
                      </ul>
                      <p className="card-get text-cf-cold-purple mt-10 mb-5">
                        Get in touch with our sales team to explore the right
                        product(s) for your payment needs and get custom
                        pricing.
                      </p>
                    </div>
                    <a
                      href="https://www.cashfree.com/contact-sales?source-action=Instant%20settlements&action=Contact%20Sales&button-id=ContactSales_Pricing"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button button-outline w-full !shadow-none">
                      Contact Sales
                      <span className="chevron" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SetttlementSection />
        <MobileSettlementSection />
        <section className="py-12 md:py-32">
          <div className="container">
            <div className="flex flex-wrap items-center justify-between flex-col-reverse md:flex-row">
              <div className="w-full md:w-1/2">
                <img
                  className="mt-7 md:mt-0"
                  src="/img/instant-settlements/asset-5.png"
                  alt=""
                  width="1235"
                  height="1024"
                />
                {/* <img
                  className="block md:hidden mt-2"
                  src="/img/instant-settlements/asset-5-sm.svg"
                  alt=""
                  width="349"
                  height="328"
                /> */}
              </div>
              <div className="w-full md:p-[20px] md:pl-[38px] md:w-6/12 md:mt-[-30px] lg:mt-[-40px]">
                <div className="text-3md md:text-shs font-semibold text-cf-hero max-w-[500px] mb-8 font-body">
                  Stay on top of business operations with
                  intelligent&nbsp;reporting
                </div>
                <ul className="p-0 mb-10 list-none max-w-full">
                  {[
                    {
                      id: 0,
                      listItem: (
                        <>
                          View and download daily, weekly, and monthly reports
                          or generate custom&nbsp;reports.
                        </>
                      ),
                    },
                    {
                      id: 1,
                      listItem: (
                        <>
                          Understand business cash flow better through detailed
                          reports on payments, settlements, refunds, settlement
                          reconciliation,&nbsp;etc.
                        </>
                      ),
                    },
                    {
                      id: 2,
                      listItem: (
                        <>
                          Analyze settlement status with easy-to-read graphs and
                          downloadable custom reports through email, dashboard
                          view, or&nbsp;SFTP.
                        </>
                      ),
                    },
                  ].map(({ id, listItem }) => (
                    <li className="mb-4 flex" key={id}>
                      <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-2 bg-cf-primary flex-none">
                        <Icon
                          name="tick"
                          width="9.21px"
                          height="6.29px"
                          fill="#fff"
                        />
                      </span>
                      <h3 className="font-body !m-0 text-2.5sm md:text-[15px] md:leading-6">
                        {listItem}
                      </h3>
                    </li>
                  ))}
                </ul>
                <a
                  className="button button-green"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=Instant%20settlements&action=Sign%20Up&button-id=StartNow_StayOnTop">
                  Create Account <span className="chevron" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-cf-light pt-12 pb-0 md:pt-24 md:pb-10">
          <div className="container">
            <div className="font-heading text-lg md:text-xl font-semibold text-cf-hero max-w-[560px] mb-8">
              Advanced Payment Gateway features available for all
            </div>
            <a
              className="button button-green mb-[60px]"
              href="https://merchant.cashfree.com/merchants/signup?source-action=Instant%20settlements&action=Sign%20Up&button-id=StartNow_AdvancedPaymentGateway">
              Learn more <span className="chevron" />
            </a>

            <div className="flex flex-wrap items-stretch md:mx-[-20px]">
              {[
                {
                  id: 0,
                  icon: '/img/icons/green-heart.svg',
                  heading: 'Customized Checkout',
                  description: (
                    <>
                      Customize the checkout page to look like your website or
                      mobile&nbsp;application.
                    </>
                  ),
                  learnMore: null,
                },
                {
                  id: 1,
                  icon: '/img/icons/token-vault.svg',
                  heading: 'Token Vault',
                  description: (
                    <>
                      Process saved card payments securely with India’s first
                      interoperable card tokenization&nbsp;solution.
                    </>
                  ),
                  learnMore: 'https://www.cashfree.com/card-tokenization/',
                },
                {
                  id: 2,
                  icon: '/img/icons/pink-petal.svg',
                  heading: 'Recurring Payments',
                  description: (
                    <>
                      Auto-debit funds for repeat payments via cards, UPI and
                      net&nbsp;banking.
                    </>
                  ),
                  learnMore: 'https://www.cashfree.com/recurring-payment/',
                },
                {
                  id: 3,
                  icon: '/img/icons/pause-circle.svg',
                  heading: 'Pre-authorization',
                  description: (
                    <>
                      Block funds when your customer places an order. If the
                      order is modified or canceled within a week, process the
                      refund instantly without any extra&nbsp;charge.
                    </>
                  ),
                  learnMore: 'https://www.cashfree.com/preauthorization/',
                },
                {
                  id: 4,
                  icon: '/img/icons/purple-up-arrow.svg',
                  heading: 'Higher than Industry Success Rate',
                  description: (
                    <>
                      With smart dynamic rerouting between multiple bank payment
                      gateways, experience the highest success rate
                      every&nbsp;time.
                    </>
                  ),
                  learnMore: null,
                },
                {
                  id: 5,
                  icon: '/img/icons/green-bag.svg',
                  heading: 'Real-time Reconciliation',
                  description: (
                    <>
                      Know the real-time status of all customer payments and
                      avoid ambiguity with real-time&nbsp;reconciliation.
                    </>
                  ),
                  learnMore: null,
                },
              ].map(({ id, description, heading, icon, learnMore }) => (
                <div
                  key={id}
                  className="w-full md:w-1/2 lg:w-1/3 md:px-[20px] mb-[40px]">
                  <div className="flex justify-start">
                    <img
                      className="max-w-[40px] max-h-[40px] mr-4"
                      src={icon}
                      width="40"
                      height="40"
                      alt=""
                    />
                    <div>
                      <div>
                        <h3 className="font-body font-semibold text-2md mb-1.5">
                          {heading}
                        </h3>
                        <p className="text-2.5sm md:text-2sm">{description}</p>
                        {learnMore && (
                          <a
                            // type="button"
                            href={learnMore}
                            className="btn mt-4 p-0 text-cf-green group">
                            Learn More
                            <svg
                              className="ml-2.5 group-hover:translate-x-[3px] transition-transform duration-300"
                              width="6"
                              height="11"
                              viewBox="0 0 6 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1 1L5 5.5L1 10"
                                stroke="#05C16E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Scrollspy>

      <div className="container instant-settlement-faqs">
        <FAQ
          open="0"
          data={[
            {
              id: 0,
              q:
                'How do I avail Instant Settlements from Cashfree Payment Gateway?',
              a: (
                <p className="faq-p">
                  In order to enable Instant Settlements on Payment Gateway for
                  your business, please reach out to us at{' '}
                  <a href="mailto:care@cashfree.com" className="email-link">
                    care@cashfree.com
                  </a>
                </p>
              ),
            },
            {
              id: 1,
              q:
                'Will the settlement report reflect the Instant Settlements in real-time?',
              a: (
                <p className="faq-p">
                  The settlement once made to your account will reflect
                  instantly on your settlement reports.
                </p>
              ),
            },
            {
              id: 2,
              q: 'Can the settlements be processed on bank holidays?',
              a: (
                <p className="faq-p">
                  The holiday settlements in Cashfree Payment Gateway lets you
                  access your funds even on bank holidays. Charges may vary for
                  holiday settlements. Contact{' '}
                  <a href="mailto:care@cashfree.com" className="email-link">
                    care@cashfree.com
                  </a>{' '}
                  to know more.
                </p>
              ),
            },
            {
              id: 3,
              q:
                'Will the transactions in pending status be settled to my account?',
              a: (
                <p className="faq-p">
                  No, only the transactions that are successful will be settled
                  in your account.
                </p>
              ),
            },
            {
              id: 4,
              q:
                'What if my settlement cycle is 15 minutes and my payment is still being processed after 15 minutes? Will I get the amount settled instantly for such cases?',
              a: (
                <p className="faq-p">
                  No, your transaction has to be successful in order for you to
                  avail Instant Settlements.
                </p>
              ),
            },
            {
              id: 5,
              q:
                'As a Cashfree Payments merchant, I should be able to get instant settlements service to my bank account directly with the highest frequency of 30 mins so that I can use the funds as per my requirements directly from my bank account. Is this possible?',
              a: (
                <p className="faq-p">
                  Yes, with our Payment Gateway Instant Settlements, you can get
                  access to your funds within 30 minutes of payment capture.
                  Please send us an email to{' '}
                  <a href="mailto:care@cashfree.com" className="email-link">
                    care@cashfree.com
                  </a>{' '}
                  to know more.
                </p>
              ),
            },
            {
              id: 6,
              q:
                'Can I get instant settlements on specific transactions I prefer and not on all transactions?',
              a: (
                <p className="faq-p">
                  Yes, you can get specific transactions settled with On-Demand
                  Settlements. Please reach out to us at{' '}
                  <a href="mailto:care@cashfree.com" className="email-link">
                    care@cashfree.com
                  </a>{' '}
                  to know more.
                </p>
              ),
            },
            {
              id: 7,
              q:
                'Can I download a detailed report for each instant settlement which will consist of all the transactions and the charges corresponding so that I can easily reconcile the data?',
              a: (
                <p className="faq-p">
                  Yes, you can download the settlement report for all Instant
                  Settlements from within your Payment Gateway dashboard.
                </p>
              ),
            },
          ]}
        />
      </div>

      <OtherProducts
        data={[
          {
            id: 0,
            title: 'Payment Links',
            body: (
              <>
                No-code payment links to collect payments over WhatsApp, SMS,
                Facebook, Twitter and other&nbsp;channels
              </>
            ),
            url: 'https://www.cashfree.com/payment-links',
            iconname: '/img/icons/payment-links.svg',
          },
          {
            id: 1,
            title: 'Subscriptions',
            body: (
              <>
                Accept recurring payments by auto-debiting customers’ accounts
                via standing instructions on card, e-mandate via net banking
                option or UPI&nbsp;AutoPay.
              </>
            ),
            url: 'https://www.cashfree.com/recurring-payment',
            iconname: '/img/icons/subscription.svg',
          },
          {
            id: 2,
            title: 'Easy Split ',
            body: (
              <>
                Split vendor commissions and manage marketplace&nbsp;settlements
              </>
            ),
            url: 'https://www.cashfree.com/easy-split/split-payment-gateway',
            iconname: '/img/icons/easy-split.svg',
          },
          {
            id: 3,
            title: 'AutoCollect ',
            body:
              'Collect and reconcile IMPS/NEFT/UPI payments using unique virtual accounts and UPI IDs.',
            url: 'https://www.cashfree.com/auto-e-collect',
            iconname: '/img/icons/auto-collect.svg',
          },
        ]}
      />
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=Instant%20settlements&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=Instant%20settlements&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
};

export default InstantSettlements;
