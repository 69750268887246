// import React, { useEffect, useRef, useCallback } from 'react';
// import elementOffset from '../../../utils/elementOffset';
// import elementVisibility from '../../../utils/elementVisibility';

import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './SetttlementSection.scss';

gsap.registerPlugin(ScrollTrigger);

function SetttlementSection() {
  const containerRef = useRef();
  const pinWrapperRef = useRef();

  useEffect(() => {
    const section = pinWrapperRef.current;
    // const section = containerRef.current;
    const card = document.querySelector('.v-desktop .inner');
    const back = document.querySelector('.v-desktop .back');
    const badge1 = document.querySelector('.v-desktop .badge-1');
    const badge1text = document.querySelector('.v-desktop .first-text');
    const clock = document.querySelector('.v-desktop .clock');
    const badge = document.querySelector('.v-desktop .t2-badge');
    const zap = document.querySelector('.v-desktop .zap');
    const mm = gsap.matchMedia();
    gsap.set('.accelerate-heading span', {
      paddingTop: '2.5rem',
      opacity: 0,
      translateY: 10,
    });
    mm.add('(min-width:768px) and (max-width:1023px)', () => {
      // gsap.set('.accelerate-heading span', {
      //   paddingTop: '2.5rem',
      //   opacity: 0,

      //   translateY: 10,
      // });
      gsap.set(zap, { opacity: 0 });
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          // markers: true,
          // start: '-50px top',
          start: 'center center',
          // end: () => `+=200`,
          // end: '+=800px',
          toggleActions: 'restart none none reverse',
          // scrub: true,
          pin: true,
          anticipatePin: 1,
          // invalidateOnRefresh:tue
        },
        defaults: { ease: 'none' },
      });
      const start = 0;
      tl.fromTo(
        card,
        { rotationY: 0, opacity: 1 },
        { rotationY: 180, opacity: 1 },
      )
        .fromTo(
          badge1,
          { translateY: 5 },
          {
            translateY: -42,
          },
          start,
        )
        .fromTo(
          clock,
          { opacity: 1 },
          {
            opacity: 0,
          },
          start,
        )
        .fromTo(
          zap,
          { opacity: 0 },
          {
            opacity: 1,
          },
          start,
        )
        .fromTo(
          badge,
          { background: '#ffffff', color: '#000000' },
          { background: '#240253', color: '#ffffff' },
          start,
        )
        .fromTo(
          badge1text,
          { opacity: 1 },
          {
            opacity: 0,
          },
          start,
        )
        .fromTo(
          back,
          { rotationY: -180, opacity: 1 },
          { rotationY: 0, opacity: 1 },
          start,
        )
        .fromTo(
          '.typical-heading span',
          {
            opacity: 1,
            paddingTop: '0rem',
            translateY: 0,
          },
          {
            opacity: 0,
            paddingTop: '2.5rem',
            translateY: 10,
            ease: 'power3.out',
          },
          start,
        )
        .fromTo(
          '.accelerate-heading span',
          {
            paddingTop: '2.5rem',
            translateY: 10,
            opacity: 0,
          },
          {
            paddingTop: '0rem',
            translateY: 0,
            opacity: 1,
            // duration: 0.25,
            ease: 'power3.out',
          },
          0.3,
        )
        .to({}, { duration: 0.2 });
      return () => {
        tl.kill();
      };
    });
    mm.add('(min-width:1024px) and (max-width:1700px)', () => {
      // gsap.set('.accelerate-heading span', {
      //   // opacity: 0,
      //   paddingTop: '2.5rem',
      //   // rotate: 15,
      //   translateY: 10,
      // });
      gsap.set(zap, { opacity: 0 });
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          // start: '+=350px center',
          // start: '-10% top',
          start: 'center center',
          // end: () => `+=${section.innerHeight}`,
          toggleActions: 'restart none none reverse',
          pin: true,
          anticipatePin: 1,
          // markers: true,
          // scrub: true,
        },
        defaults: { ease: 'none' },
      });

      tl.fromTo(
        card,
        { rotationY: 0, opacity: 1 },
        { rotationY: 180, opacity: 1 },
      )
        .fromTo(
          badge1,
          { translateY: 0 },
          {
            translateY: -57,
          },
          0,
        )
        .fromTo(
          clock,
          { opacity: 1 },
          {
            opacity: 0,
          },
          0,
        )
        .fromTo(
          zap,
          { opacity: 0 },
          {
            opacity: 1,
          },
          0,
        )
        .fromTo(
          badge,
          { background: '#ffffff', color: '#000000' },
          { background: '#240253', color: '#ffffff' },
          0,
        )
        .fromTo(
          badge1text,
          { opacity: 1 },
          {
            opacity: 0,
          },
          0,
        )
        .fromTo(
          back,
          { rotationY: -180, opacity: 1 },
          { rotationY: 0, opacity: 1 },
          0,
        )
        .fromTo(
          '.typical-heading span',
          {
            opacity: 1,
            paddingTop: '0rem',
            translateY: 0,
          },
          {
            opacity: 0,
            // paddingTop: '2.5rem',
            translateY: 10,
            // duration: 0.3,
            ease: 'power3.out',
          },
          0,
        )
        .fromTo(
          '.accelerate-heading span',
          {
            opacity: 0,
            paddingTop: '2.5rem',
            translateY: 10,
          },
          {
            opacity: 1,
            paddingTop: '0rem',
            translateY: 0,
            // duration: 0.5,
            ease: 'power3.out',
          },
          0.3,
        )
        .to({}, { duration: 0.2 });
      return () => {
        tl.kill();
      };
    });
    mm.add('(min-width:1701px)', () => {
      // gsap.set('.accelerate-heading span', {
      //   // opacity: 0,
      //   paddingTop: '2.5rem',
      //   // rotate: 15,
      //   translateY: 10,
      // });
      gsap.set(zap, { opacity: 0 });
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          // start: '+=350px center',
          // start: '-10% top',
          start: 'center center',
          // end: () => `+=${section.innerHeight}`,
          toggleActions: 'restart none none reverse',
          // pin: true,
          // anticipatePin: 1,
          // markers: true,
          // scrub: true,
        },
        defaults: { ease: 'none' },
      });

      tl.fromTo(
        card,
        { rotationY: 0, opacity: 1 },
        { rotationY: 180, opacity: 1 },
      )
        .fromTo(
          badge1,
          { translateY: 0 },
          {
            translateY: -57,
          },
          0,
        )
        .fromTo(
          clock,
          { opacity: 1 },
          {
            opacity: 0,
          },
          0,
        )
        .fromTo(
          zap,
          { opacity: 0 },
          {
            opacity: 1,
          },
          0,
        )
        .fromTo(
          badge,
          { background: '#ffffff', color: '#000000' },
          { background: '#240253', color: '#ffffff' },
          0,
        )
        .fromTo(
          badge1text,
          { opacity: 1 },
          {
            opacity: 0,
          },
          0,
        )
        .fromTo(
          back,
          { rotationY: -180, opacity: 1 },
          { rotationY: 0, opacity: 1 },
          0,
        )
        .fromTo(
          '.typical-heading span',
          {
            opacity: 1,
            paddingTop: '0rem',
            translateY: 0,
          },
          {
            opacity: 0,
            // paddingTop: '2.5rem',
            translateY: 10,
            // duration: 0.3,
            ease: 'power3.out',
          },
          0,
        )
        .fromTo(
          '.accelerate-heading span',
          {
            opacity: 0,
            paddingTop: '2.5rem',
            translateY: 10,
          },
          {
            opacity: 1,
            paddingTop: '0rem',
            translateY: 0,
            // duration: 0.5,
            ease: 'power3.out',
          },
          0.3,
        )
        .to({}, { duration: 0.2 });
      return () => {
        tl.kill();
      };
    });
    return () => {
      mm.kill();
    };
  }, []);
  return (
    <>
      <section
        className="bg-cf-light relative hidden md:flex justify-center items-center"
        ref={containerRef}>
        <section className="bg-cf-light z-0 pb-[49px]">
          <div className="container" ref={pinWrapperRef}>
            <div className="flex justify-center items-center">
              <div className="absolute font-heading md:text-center text-lg md:text-xl text-cf-hero font-semibold max-w-[660px] mx-auto mb-0 md:mb-4 pt-24 typical-heading">
                <span>Typical</span> <span>settlements</span> <span>are</span>{' '}
                <span>processed on</span>{' '}
                <span className="text-cf-faded-black">T+2 working days</span>
              </div>
              <div className="font-heading md:text-center text-lg md:text-xl text-cf-hero font-semibold max-w-[610px] mx-auto mb-0 md:mb-4 pt-24 accelerate-heading">
                <span>Accelerate</span> <span>your</span>{' '}
                <span>settlements</span> <span>with</span>{' '}
                <span className="text-cf-primary">Instant settlements</span>
              </div>
            </div>
            <div
              className="overflow-x-auto max-w-full typical-illustration-container v-desktop relative"
              // ref={typicalSectionRef}
            >
              <div className="flex flex-row items-stretch typical-illustration mx-[-20px] transition duration-500">
                {[
                  {
                    id: 0,
                    image: '/img/instant-settlements/enter-upi-w.png',
                    description:
                      'Customer enters the payment details in Cashfree’s checkout forms',
                    after: null,
                  },
                  {
                    id: 1,
                    image: '/img/instant-settlements/otp.png',
                    description:
                      'The transaction is authenticated by the customer using OTP verification',
                    after: null,
                  },
                  {
                    id: 2,
                    image: '/img/instant-settlements/payment-done.png',
                    description:
                      'The amount is received at Cashfree’s acquiring banks',
                    after: null,
                  },
                ].map(({ id, description, image, after }) => (
                  <div
                    key={id}
                    className="mx-[20px] mb-10 relative z-10 process-card">
                    <div className="rounded-sm mb-5 max-w-[286px] max-h-[286px]">
                      <img className="w-full" src={image} alt="" />
                    </div>
                    <div>
                      <p className="text-sm text-center">{description}</p>
                    </div>
                    {after}
                  </div>
                ))}
                <div className="mx-[20px] mb-10 relative z-10 process-card">
                  <div className="inner">
                    <div className="rounded-sm mb-5 max-w-[286px] max-h-[286px]">
                      <img
                        className="w-full"
                        src="/img/instant-settlements/amount-credit.png"
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="text-sm text-center">
                        Amount is credited in merchant&apos;s account in T+2
                        days after{' '}
                        <span className="inline-block">
                          payment gateway deductions.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="back">
                    <div className="rounded-sm mb-5 max-w-[286px] max-h-[286px]">
                      <img
                        className="w-full"
                        src="/img/instant-settlements/amount-credit-back.png"
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="text-sm text-center">
                        Cashfree Payments credits the merchant&apos;s accounts
                        instantly after{' '}
                        <span className="inline-block">
                          Payment Gateway deduction
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="absolute top-0 p-2 lg:p-3 xl:p-4 t2-badge flex items-center z-50 bg-white">
                    <img
                      className="zap"
                      src="/img/icons/yellow-zap.svg"
                      alt=""
                      width="32"
                      height="32"
                    />
                    <img
                      className="clock absolute"
                      src="/img/icons/clock.svg"
                      alt=""
                      width="32"
                      height="32"
                    />
                    <div className="text-[10px] lg:text-tiny xl:text-2.5sm pl-2 lg:pl-4 flex flex-col h-[42px] overflow-hidden">
                      <div className="badge-1">
                        <div className="mb-4 first-text">
                          Merchant receives money in T+2 days
                        </div>
                        <div>Merchant receives the money instantly</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default SetttlementSection;
